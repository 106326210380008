<template>
	<div class="gg-container">
		<div class="search-container">
			<div class="search-container-fn-input" v-if="is_admin=='1'">
				<label>商户账套/商户名称：</label>
				<el-input size="small" placeholder="请输入商户账套/商户名称" prefix-icon="el-icon-search" clearable
					@change="getList('restPage')" v-model="searchParams.merchant_id">
				</el-input>
			</div>
			<div class="search-container-fn-input">
				<label>商户状态：</label>
				<el-select v-model="searchParams.status" clearable @change="getList('restPage')" placeholder="请选择" size="small">
					<el-option v-for="item in merchantStatus" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search-container-fn-input">
				<label>创建时间：</label>
				<el-date-picker v-model="searchParams.created_at" size="small" type="datetimerange"
					:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					clearable format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="getList('restPage')" align="left">
				</el-date-picker>
			</div>

			<div class="search-container-fn-input" v-if="is_admin=='1'" style="margin-left: auto;padding: 7px 8px;">
				<el-button icon="el-icon-plus" type="primary" size="mini" @click="handleAdd('add')">新增商户</el-button>
			</div>
		
		</div>
		<!-- table-->
		<el-table :data="tableData" v-loading="listLoading" border style="width: 100%">
			<el-table-column prop="merchant_name" label="机构名称" align="center" width="210">
			</el-table-column>
			<el-table-column prop="mer_set" label="账套" align="center" width="150">
			</el-table-column>
			
			<el-table-column prop="arrange_type_name" label="部署方式" align="center" width="140">
			</el-table-column>
			<el-table-column prop="mall_type_name" label="商城系统" align="center" width="140">
			</el-table-column>
			<el-table-column prop="created_at" align="center" label="创建时间">
			</el-table-column>
			<el-table-column align="center" label="商户状态" width="160">
				<template slot-scope="{row}">
					<el-switch class="switch" :active-value="1" :disabled="switchDis" :inactive-value="2"
						@change="changeStatus($event,row)" v-model="row.status" active-text="启用" inactive-text="禁用"
							active-color="#13ce66" inactive-color="#C8C8C8">
					</el-switch>
					<!-- {{row.status == 1 ? '启用' : '禁用'}} -->
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" width="250">
				<template slot-scope="{row}">
					<el-button icon="el-icon-tickets" style="padding: 7px 8px;" size="mini" type="primary" @click="assignPermission(row)">分配权限</el-button>
					<!-- <el-button icon="el-icon-tickets" style="padding: 7px 8px;" size="mini" type="primary" @click="handleAdd('detail',row)">查看</el-button> -->
					<el-button style="padding: 7px 8px;margin-left: 5px;" icon="el-icon-edit" size="mini" type="success" @click="handleAdd('edit',row)">编辑</el-button>
				</template>

			</el-table-column>

		</el-table>
		<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
			@pagination="getList" :pageSizes="pageSize"/>

		<!--分配权限-->
		<el-dialog width="80%" title="分配权限" :visible.sync="dialogPermissionVisible" v-if="dialogPermissionVisible">
			<el-form  label-width="100px" class="demo-ruleForm">
				<el-form-item label="商户名称">
					{{ agentName }}
				</el-form-item>
				<el-form-item label="权限">
					<el-tree
						:default-checked-keys="auth_ids"
						:props="{children:'children',label:'name'}"
						:data="permissions"
						show-checkbox
						node-key="id"
						ref="tree"
						highlight-current
						@check="handleCheckChange"
						>
					</el-tree>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleAssignPermission">确定分配</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>


		<el-dialog width="760px" :title="dialogTitle" :visible.sync="dialogTableVisible" v-if="dialogTableVisible">
			<!-- <physicianDetail doctorType="1" :type="dialogType" :status="currUser.status" :id="currUser.id" @editSuccess="editSuccess"></physicianDetail> -->
			<!-- 审核 -->
			<div class="auditForm">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
					<!-- <el-form-item label="上级商户" prop="p_id" v-if="is_admin==1">
						<el-select v-model="ruleForm.p_id" :disabled="diaglogType != 'add'" clearable
							placeholder="请选择上级商户" size="small" @change="chanes">
							<el-option :value="0" label="顶级商户"></el-option>
							<el-option style="margin-left:20px" v-for="val in agentsList" :key="val.id"
								:label="val.merchant_name" :value="val.id">
							</el-option>
						</el-select>

					</el-form-item> -->
			
					<el-form-item label="商户名称:" prop="merchant_name">
						<template v-if="diaglogType=='detail'">
							{{ruleForm.merchant_name}}
						</template>
						<template v-else>
							<el-input v-model="ruleForm.merchant_name" maxlength="40" show-word-limit></el-input>
						</template>
					</el-form-item>

					<el-form-item label="账套" prop="mer_set">
						<template v-if="diaglogType != 'detail'">
							<el-input v-model="ruleForm.mer_set" maxlength="40" show-word-limit></el-input>
						</template>
						<template v-else>
							{{ruleForm.mer_set}}
						</template>
					</el-form-item>
					
					<el-form-item label="部署方式" prop="arrange_type">
						<template>
							<el-radio-group v-model="ruleForm.arrange_type">
								<el-radio :label="1">格格健康</el-radio>
								<el-radio :label="2">独立部署</el-radio>
							</el-radio-group>
						</template>
						
					</el-form-item>

					<el-form-item label="慢病接口域名" prop="cdm_api_url" v-if="ruleForm.arrange_type == 2">
						<template>
							<el-input v-model="ruleForm.cdm_api_url" maxlength="40" show-word-limit placeholder="包含http的域名地址,如:https://cdm-api.gegejk.com"></el-input>
						</template>
					</el-form-item>

					<el-form-item label="商城系统" prop="mall_type">
						<template>
							<el-radio-group v-model="ruleForm.mall_type">
								<el-radio :label="0">无商城</el-radio>
								<el-radio :label="1">格格商城</el-radio>
								<el-radio :label="2">独立商城</el-radio>
								<el-radio :label="3">自有商城</el-radio>
								<el-radio :label="4">三方商城</el-radio>
							</el-radio-group>
						</template>
					</el-form-item>

					<el-form-item label="商城接口域名" prop="mall_api_url" v-if="ruleForm.mall_type == 2">
						<template>
							<el-input v-model="ruleForm.mall_api_url" maxlength="40" show-word-limit placeholder="包含http的域名地址,如:https://shop-api.gegejk.com"></el-input>
						</template>
					</el-form-item>
					
					<el-form-item label="患者端小程序" prop="mini_app_type">
						<template>
							<el-radio-group v-model="ruleForm.mini_app_type">
								<el-radio :label="1">格格健康小程序</el-radio>
								<el-radio :label="2">独立小程序</el-radio>
							</el-radio-group>
						</template>
					</el-form-item>

					<el-form-item label="小程序app_id" prop="wx_app_id" v-if="ruleForm.mini_app_type == 2">
						<template>
							<el-input v-model="ruleForm.wx_app_id" maxlength="100" show-word-limit></el-input>
						</template>
					</el-form-item>

					<el-form-item label="小程序app_secret" prop="app_secret" v-if="ruleForm.mini_app_type == 2">
						<template>
							<el-input v-model="ruleForm.app_secret" maxlength="100" show-word-limit></el-input>
						</template>
					</el-form-item>

					<el-form-item label="微信支付方式" prop="wx_pay_type" v-if="ruleForm.mini_app_type == 2">
						<template>
							<el-radio-group v-model="ruleForm.wx_pay_type">
								<el-radio :label="1">格格健康微信支付</el-radio>
								<el-radio :label="2">自有微信支付</el-radio>
							</el-radio-group>
						</template>
					</el-form-item>

					<el-form-item label="微信商户id" prop="mechant_id" v-if="ruleForm.wx_pay_type == 2">
						<template>
							<el-input v-model="ruleForm.mechant_id" maxlength="100" show-word-limit></el-input>
						</template>
					</el-form-item>

					<el-form-item label="微信支付秘钥" prop="wx_pay_secret" v-if="ruleForm.wx_pay_type == 2">
						<template>
							<el-input v-model="ruleForm.wx_pay_secret" maxlength="100" show-word-limit></el-input>
						</template>
					</el-form-item>


					<el-form-item label="权限">
						<el-tree
							:default-checked-keys="auth_ids"
							:props="{children:'children',label:'name'}"
							:data="permissions"
							show-checkbox
							node-key="id"
							ref="tree"
							highlight-current
							@check="handleCheckChange"
							>
						</el-tree>
					</el-form-item>


					
					<el-form-item>
						<el-button type="info" @click="dialogTableVisible=false">取消</el-button>
						<el-button v-if="diaglogType=='add'" type="primary" @click="submitForm">确定</el-button>
						<el-button size="mini" v-else-if="diaglogType=='edit'" type="primary" @click="submitForm">保存</el-button>
						<el-button size="mini" v-else-if="diaglogType=='detail'" type="primary" @click="editForm">编辑</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import Pagination from '@/components/Pagination' // secondary package based on el-pagination
	import physicianDetail from "./components/physicianDetail.vue";
	import {
		agents
	} from '@/api/auth'
	import {
		getMerchantList,
		addMerchant,
		updateMerchant,
		statusMerchant,
		assignPermission
	} from "@/api/cdm/cdm-merchant.js";
	import {getPermissions} from '@/api/cdm/auth.js'
	import {
		Message
	} from "element-ui";
	import {
		mapState
	} from "vuex";

	export default {
		name: "agent",
		components: {
			Pagination
		},
		created() {
			
			this.getList();
			if (this.is_admin == '1') {
				this.getAgents();
				this.getPermissions()
			}
		},
		data() {
			return {
				agentId:0,
				auth_ids:[],
				permission_ids:[],
				agentName:'',
				dialogPermissionVisible:false,
				permissions:[],
				agentsList: [],
				diaglogType: 'add',
				switchDis: false,
				switchLoding: false,
				merchantStatus: [
					{
						label: '启用',
						value: 1
					},
					{
						label: '禁用',
						value: 2
					},
				],
				dialogTitle: '',
				dialogType: '',
				datetimerange: [],
				currUser: {},
				dialogTableVisible: false,
				searchParams: {
					orderTime: []
				},
				total: 0,
				listLoading: false,

				listQuery: {
					page: 1,
					limit: 5,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: '+id',
				},
				tableData: [],
				options: [],
				ruleForm: {
					is_y3: '',
					p_id: '0',
					mer_set: '',
					arrange_type:1,
					mall_type:0,
					mini_app_type:1,
					wx_pay_type:1
				},
				rules: {
					p_id: [{
						required: true,
						message: '请选择上级商户'
					}],
					arrange_type: [{
						required: true,
						message: '请选择部署方式'
					}],
					mer_set: [{
						required: true,
						message: '请填写账套'
					}],
					merchant_name: [{
						required: true,
						message: '请填写商户名称'
					}],
					mall_type: [{
						required: true,
						message: '请选择商城系统'
					}],
					mini_app_type: [{
						required: true,
						message: '请选择患者端小程序'
					}],
					wx_app_id: [{
						required: true,
						message: '请填写小程序app_id'
					}],
					app_secret: [{
						required: true,
						message: '请填写小程序app_secret'
					}],
					mechant_id: [{
						required: true,
						message: '请填写商户id'
					}],
					wx_pay_secret: [{
						required: true,
						message: '请填写微信支付密钥'
					}],
			
					wx_pay_type: [{
						required: true,
						message: '请选择微信支付方式'
					}],
				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
        pageSize: [5,10,20,30,40,50],
			};
		},
		computed: {
			...mapState({
				is_admin: state => state.user.is_admin,
				agents:state => state.options.agents
			}),
		},
		mounted(){
			this.$store.dispatch('options/getAgents')
		},

		methods: {
			handleAssignPermission(){
				let params = {
					agent_id:this.agentId,
					permission_ids:this.permission_ids
				}
				assignPermission(params).then(res=>{
                    if(res.code == 200){
						this.getList()
						this.dialogPermissionVisible = false
						Message({
							message: '权限分配成功',
							type: 'success'
						})
						
					}else{
						Message({
							message: '操作失败',
							type: 'error'
						})
					}
				})
			},
			handleCheckChange(e){
				this.permission_ids = this.$refs.tree.getCheckedKeys()
			},
			assignPermission(row){
				this.agentName = row.merchant_name
				this.permission_ids = []
				this.agentId = row.id
				this.auth_ids = row.permissions
				console.log(111,row)
				// this.auth_ids = row.permissions.permission_ids
				this.dialogPermissionVisible = true
			},
			getPermissions(){
				getPermissions().then(res=>{
					if(200 == res.code){
						this.permissions = res.data
					}
				})
			},
			chanes(e) {
				console.log(e)
			},
			//获取商户目录
			getAgents() {
				agents().then((res) => {
						if (res.code == 200) {
							this.agentsList = res.data;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},
			changeStatus(e, row) {
				console.log('e,row', e, row)
				this.switchDis = true
				statusMerchant(row.id, e).then(res => {
					this.switchDis = false
					if (res.code == 200) {
						this.getList();
						this.getAgents();
						var msg = e == 1 ? '已开启' : '已禁用';
						this.$message.success(msg)
					} else {
						if (e == 2) {
							row.status = 1
						}
						if (e == 1) {
							row.status = 2
						}
						// row.id = e == 1 ? 2 : 1
						this.$message.error(res.msg)
					}
					if (res.code == 7403) {
						row.status = 2
					} else if (res.code == 7404) {
						row.status = 1
					}
				})
			},

			getList(type) {
        console.log('getList触发')
        if (type == 'restPage') {
          this.listQuery.page = 1
        }
				this.listLoading = true
				let params = {
					...this.searchParams
				}
        this.searchParams.page = this.listQuery.page
				params.page = this.listQuery.page;
				params.page_size = this.listQuery.limit
				getMerchantList(params).then(res => {

					if (res.code == 200) {
						let _data = res.data
						this.tableData = _data.list
						this.total = _data.total
						this.listQuery.limit = _data.per_page
					}
					console.log('this.tableData', this.tableData)

					this.listLoading = false
				}).catch(error => {
					Message({
						message: error.message,
						type: 'error'
					})
					this.listLoading = false

				})
			},
			editForm() {
				this.diaglogType = 'edit'
				this.dialogTitle = '修改机构'
			},
			submitForm() {
				if (this.diaglogType == 'add') {
					this.addForm()
				} else if (this.diaglogType == 'edit') {
					this.updateForm()
				}
			},
			addForm() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						let params = {
							...this.ruleForm
						}
						params.permission_ids = this.permission_ids
						addMerchant(params).then(response => {
							let data = response
							if (data.code == 200) {
								let _data = data.data
								Message({
									message: '商户添加成功',
									type: 'success'
								})
								this.getList()
								this.dialogTableVisible = false

							} else {
								Message({
									message: data.msg ? data.msg : data.message,
									type: 'error'
								})
							}
							this.listLoading = false
						}).catch(error => {
							Message({
								message: error.message,
								type: 'error'
							})
							this.listLoading = false
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			updateForm() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						let params = {
							...this.ruleForm
						}
						params.permission_ids = this.permission_ids
						updateMerchant(params.id, params).then(response => {
							let data = response
							if (data.code == 200) {
								let _data = data.data
								Message({
									message: '商户修改成功',
									type: 'success'
								})
								this.getList()
								this.dialogTableVisible = false

							} else {
								Message({
									message: data.msg ? data.msg : data.message,
									type: 'error'
								})
							}

							this.listLoading = false
						}).catch(error => {
							Message({
								message: error.message,
								type: 'error'
							})
							this.listLoading = false

						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			dateChange(val) {
				if (val && val.length) {
					this.searchParams.created_at = JSON.stringify(val)
				} else {
					this.searchParams.created_at = []
				}
				this.getList()
			},
			handleAdd(type, row) {
				this.diaglogType = type
				if (type == 'add') {
					this.ruleForm = {
						arrange_type:1,
						mini_app_type:1,
						mall_type:0,
						wx_pay_type:1

					}
					this.auth_ids = []
					this.dialogTitle = '新增机构'
				} else if (type == 'edit') {
					this.ruleForm = Object.assign({}, row)
					this.dialogTitle = '修改机构'
					this.auth_ids = row.permissions
				} else {
					this.ruleForm = Object.assign({}, row)
					this.dialogTitle = '机构详情'
				}
				this.dialogTableVisible = true
			},
			editSuccess() {
				alert('编辑成功')
				this.dialogTableVisible = false
				this.getList()
			}
		},
		filters: {
			getIsY3Title(value) {
				return value == '1' ? '是' : '否'
			}
		}
	}
</script>

<style lang="scss" scoped>
/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}

		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}

		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}

		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}

		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}

		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}

		/* 调整按钮的宽度 */
		.el-switch__core,
		.el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
