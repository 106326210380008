/*
*慢病管理-商户管理接口
*/


import request from '@/utils/request'

// 商户列表
export function getMerchantList(params) {
    return request({
        url: '/admin/merchant',
        method: 'get',
        params
    })
}

// 添加商户
export function addMerchant(data) {
    return request({
        url: '/admin/merchant',
        method: 'post',
        data
    })
}

// 修改商户
export function updateMerchant(id,data) {
    return request({
        url: `/admin/merchant/${id}/update`,
        method: 'put',
        data
    })
}

// 修改商户状态
export function statusMerchant(id,status) {
    return request({
        url: `/admin/merchant/${id}/status`,
        method: 'put',
        data:{status}
    })
}

//获取顶级商户列表
export function agents(type = 0) {
  return request({
    url: `/admin/topMerchants`,
    method: 'get',
    params:{type}
  })
}

export function assignPermission(data) {
    return request({
      url: `/admin/assignPermission`,
      method: 'post',
      data
    })
  }
  

