import request from '@/utils/request'

//审核管理 - 待审核列表 - 医生  /admin/doctors-to-examine
export function getDoctorsAuthList(query) {
	return request({
		url: '/admin/doctors-to-examine',
		method: 'get',
		params: query
	})
}


// 医师*药师管理 - 医生/药师列表  /admin/doctors
export function getDoctorsList(query) {
	return request({
		url: '/admin/doctors',
		method: 'get',
		params: query
	})
}



//删除 机构
export function delOrgan(id) {
	return request({
		url: '/admin/signOrgan/delOrgan/' + id,
		method: 'delete',
	})
}
//修改 机构
export function updateOrgan(data) {
	return request({
		url: '/admin/signOrgan/update',
		method: 'put',
		data
	})
}
// 添加 机构
export function addOrgan(data) {
	return request({
		url: '/admin/signOrgan/store',
		method: 'post',
		data
	})
}
// 机构列表
export function getOrganList(query) {
	return request({
		url: '/admin/signOrgan/index',
		method: 'get',
		params: query
	})
}


//医师*药师管理 - 医生/药师审核  /admin/doctors/:id/examine
export function examineDoctors(query) {
	return request({
		url: '/admin/doctors/' + query.id + '/examine',
		method: 'put',
		data: query
	})
}

//医师/药师管理 - 医生/药师详情 /admin/doctors/:id
export function getDoctorsDetail(query) {
	return request({
		url: '/admin/doctors/' + query.id,
		method: 'get',
		params: query
	})
}

//医师*药师管理 - 编辑
export function editDoctors(query) {
	return request({
		url: '/admin/doctors/' + query.id,
		method: 'put',
		data: query
	})
}

//上传监管
export function uploadDoctorInfo(query) {
	return request({
		url: '/admin/doctors/' + query.doctor_id + '/supervision',
		method: 'post',
		data: query
	})
}


